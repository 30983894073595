<!-- =========================================================================================
	File Name: OrderPayments.vue
	Description: Блок выбора оплат заказа
========================================================================================== -->

<template>
    <div class="vx-row">
        <div class="vx-col w-full">
            <vx-card title="Оплаты">
                <div class="mt-1">
                    <ul class="w-full"
                        v-if="paymentMethods.length">
                        <li class="flex text-sm mb-2"
                            v-for="(item, index) in paymentMethods" :key="index">
                            <div class="w-1/2">
                                <div>
                                    <span class="font-bold">{{paymentMethodName(item)}}</span>
                                    <span> - {{item.payment_amount.toString()}} руб.</span>
                                </div>
                                <p class="text-xs italic">
                                    {{ $helpers.formatDateTime(item.payment_time) }} - {{ item.manager_name }}
                                </p>
                            </div>
                            <div class="w-1/2 text-right">
                                <api-button
                                    :click="() => removePaymentMethod(index)" 
                                    :disabled="!isRemovePaymentsAllowed"
                                    v-if="isEditPaymentsAllowed"
                                    color="danger">
                                        X
                                </api-button>
                            </div>
                        </li>
                    </ul>
                    <vs-divider />
                    <api-button 
                        v-if="isEditPaymentsAllowed"
                        size="large" 
                        class="w-full" 
                        :disabled="paymentAmountLeft < 0"
                        :click="paymentAction">
                        {{paymentActionText}}
                    </api-button>
                </div>
            </vx-card>
        </div>

        <!-- Попап добавления оплат -->
        <vs-popup title="Добавить способ оплаты" :active.sync="paymentPrompt">
            <ul>
                <li v-for="type in paymentTypes" :key="type.id">
                    <div v-if="type.id != 84728">
                        <vs-radio v-model="paymentType" :vs-value="type">{{type.text}}</vs-radio>
                    </div>
                </li>
            </ul>
            <div class="con-exemple-prompt">
                <span>Введите сумму</span>
                <vs-input-number placeholder="Сумма" vs-placeholder="Сумма" v-model.number="paymentAmount"
                    min="0" :max="paymentAmountLeft" :step="0.01"/>
            </div>
            <vs-divider />
            <div class="flex flex-wrap">
                <api-button class="mb-2 mx-2 flex-auto" :click="addPaymentMethod">ДОБАВИТЬ ОПЛАТУ</api-button>
                <vs-button class="mb-2 mx-2 flex-auto" @click="paymentPrompt = false">ЗАКРЫТЬ</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
import OrderPaymentsMixin from './OrderPaymentsMixin.vue';

export default {
    mixins: [OrderPaymentsMixin],
}
</script>
