<!-- =========================================================================================
    File Name: Catalog.vue
    Description: Компонент каталога с поиском. Список товаров получается по API из UMI.
========================================================================================== -->

<template>
    <div>
        <div id="catalog-container" class="relative clearfix">
            <!-- SEARCH BAR -->
            <div class="flex flex-wrap items-stretch mb-3">
                <div class="w-auto flex-auto relative mb-3">
                    <!-- SEARCH INPUT -->
                    <vs-input class="w-full vs-input-shadow-drop vs-input-no-border d-theme-input-dark-bg mb-3" placeholder="Поиск" size="large"
                        v-model="filterForm.search" 
                        @input="debouncedGetCatalogItems(root_category_id)" 
                        @keyup.esc="filterForm.search = ''; debouncedGetCatalogItems(root_category_id)" />
                    <!-- SEARCH ICON -->
                    <div class="absolute top-0 right-0 py-4 px-6" v-show="!filterForm.search">
                        <feather-icon icon="SearchIcon" svgClasses="h-6 w-6" />
                    </div>
                    <!-- CLEAR INPUT ICON -->
                    <div class="absolute top-0 right-0 py-4 px-6" v-show="filterForm.search">
                        <feather-icon icon="XIcon" svgClasses="h-6 w-6 cursor-pointer" 
                            @click="filterForm.search = ''; debouncedGetCatalogItems(root_category_id);" />
                    </div>

                </div>
                <!-- ITEM VIEW - GRID/LIST -->
                <div class="w-auto pl-4 flex items-center justify-end mb-5">
                    <feather-icon
                        icon="GridIcon"
                        @click="setCurrentItemView('item-grid-view')"
                        class="p-2 ml-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer"
                        :svgClasses="{'text-primary stroke-current': currentItemView == 'item-grid-view'}" />
                    <feather-icon
                        icon="ListIcon"
                        @click="setCurrentItemView('item-list-view')"
                        class="p-2 ml-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer"
                        :svgClasses="{'text-primary stroke-current': currentItemView == 'item-list-view'}" />
                </div>
                <div class="w-full" />
                <template v-if="root_category_id != $globals.EVENTS_ROOT_CATEGORY_ID">
                    <v-select class="w-full md:w-1/3 mb-3"
                        :options="guids_list" v-model="filterForm.guid" :clearable="false" />
                    <div class="w-1/2 md:w-1/3 mb-3 pr-2 md:pl-2">
                        <flat-pickr class="w-full" @input="debouncedGetCatalogItems(category_id)"
                            v-model="filterForm.date_from" :config="configdateTimePicker" placeholder="C:" />
                    </div>
                    <div class="w-1/2 md:w-1/3 mb-3">
                        <flat-pickr class="w-full" @input="debouncedGetCatalogItems(category_id)"
                            v-model="filterForm.date_to" :config="configdateTimePicker" placeholder="По:" />
                    </div>
                </template>
            </div>

            <!-- SEARCH RESULT -->
            <div>
                <template v-if="catalogSubcategories">
                    <template v-if="catalogSubcategories['catalog-category']">
                        <div class="mb-2"><span class="font-bold">Категории</span></div>
                        <!-- GRID VIEW -->
                        <template v-if="currentItemView == 'item-grid-view'">
                            <div class="items-grid-view vx-row match-height" appear>
                                <div class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full"
                                    v-for="(item, propName, index) in catalogSubcategories['catalog-category']" :key="index">
                                    <item-grid-view :item="item" :class="{ 'back-category-btn' : item.label === 'back_button' }">
                                        <template slot="body">
                                            <div class="cursor-pointer item-img-container bg-white h-32 flex items-center justify-center mb-4"
                                                @click="categoryButtonClicked(item)">
                                                <feather-icon v-if="item.label === 'back_button'" icon="ArrowLeftIcon" svgClasses="h-32 w-32" />
                                                <img v-else :src="$helpers.getImageSrc(item.image)" :alt="item.name" class="grid-view-img px-4">
                                            </div>
                                            <div class="cursor-pointer item-details px-4"
                                                @click="categoryButtonClicked(item)">
                                                <div class="text-center">
                                                    <h6 class="truncate font-semibold mb-1">{{ item.name }}</h6>
                                                </div>
                                            </div>
                                        </template>
                                    </item-grid-view>
                                </div>
                            </div>
                        </template>

                        <!-- LIST VIEW -->
                        <template v-else>
                            <div class="items-list-view cursor-pointer"
                                v-for="(item, propName, index) in catalogSubcategories['catalog-category']" :key="index"
                                @click="categoryButtonClicked(item)">
                                <item-list-view :item="item" :class="{ 'back-category-btn' : item.label === 'back_button' }">
                                    <template slot="body">
                                        <div class="item-details px-4">
                                            <div class="text-center">
                                                <h6 class="truncate font-semibold mb-1">{{ item.name }}</h6>
                                            </div>
                                        </div>
                                    </template>
                                </item-list-view>
                            </div>
                        </template>
                    </template>

                    <template v-if="catalogSubcategories['timer-event-group']">
                        <div class="mb-2"><span class="font-bold">Мероприятия</span></div>
                        <div class="items-list-view cursor-pointer" v-for="(item, index) in catalogSubcategories['timer-event-group']" :key="index"
                            @click="categoryButtonClicked(item)">
                            <item-list-view :item="item">
                                <template slot="body">
                                    <div class="item-details px-4">
                                        <div class="text-center">
                                            <h6 class="truncate font-semibold mb-1">{{ item.name }}</h6>
                                        </div>
                                    </div>
                                </template>
                            </item-list-view>
                        </div>
                    </template>
                </template>

                <template v-if="catalogItems">
                    <template v-if="catalogItems['timer-bar-item']">
                        <div class="mb-2"><span class="font-bold">{{ itemsBlockTitle }}</span></div>
                        <!-- GRID VIEW -->
                        <template v-if="currentItemView == 'item-grid-view'">
                            <div class="items-grid-view vx-row match-height" appear>
                                <div class="vx-col lg:w-1/4 md:w-1/3 sm:w-1/2 w-full" v-for="(item, index) in catalogItems['timer-bar-item']" :key="index">
                                    <item-grid-view :item="item">
                                        <template slot="body">
                                            <div class="relative cursor-pointer item-img-container bg-white h-48 flex items-center justify-center mb-4"
                                                    @click="cartButtonClicked(item)">
                                                <div class="absolute top-0 right-0 z-50 mt-3 mr-3"
                                                    v-if="item.trade_offers">
                                                        <vs-chip color="primary">Есть опции</vs-chip>
                                                </div>
                                                <img :src="$helpers.getImageSrc(item.image)" :alt="item.name" class="grid-view-img px-4">
                                            </div>
                                            <div class="flex flex-col items-center cursor-pointer item-details"
                                                @click="cartButtonClicked(item)">
                                                <div class="xl:w-full flex-auto text-center">
                                                    <h6 class="truncate font-semibold mb-1">{{ item.name }}</h6>
                                                </div>
                                                <div class="flex-1 flex flex-col justify-center items-center">
                                                    <h6 class="font-bold line-through" v-if="item.original_price != item.price">{{ item.original_price }} руб.</h6>
                                                    <h6 class="font-bold">{{ item.price }} руб.</h6>
                                                </div>
                                            </div>
                                            <div v-if="isInOrder(item) != -1" 
                                                class="flex flex-wrap mt-2">
                                                <api-button 
                                                    :click="() => removeItemFromOrder(item)"
                                                    text-color="black" 
                                                    icon="close" 
                                                    type="flat">
                                                        УДАЛИТЬ
                                                </api-button>
                                            </div>
                                        </template>
                                    </item-grid-view>
                                </div>
                            </div>
                        </template>
                        <!-- LIST VIEW -->
                        <template v-else>
                            <div class="items-list-view cursor-pointer"
                                v-for="item in catalogItems['timer-bar-item']" :key="item.objectID">
                                <item-list-view :item="item">
                                    <template slot="body">
                                        <div class="vx-row item-details no-gutter">
                                            <div class="absolute top-0 right-0 z-50 mt-3 mr-3"
                                                v-if="item.trade_offers">
                                                    <vs-chip color="primary">Есть опции</vs-chip>
                                            </div>
                                            <div class="vx-col sm:w-2/3 cursor-pointer flex flex-col items-center justify-center"
                                                @click="cartButtonClicked(item)">
                                                <div class="p-4 pt-6">
                                                    <h6 class="item-name text-xl text-primary font-semibold mb-2">{{ item.name }}</h6>
                                                </div>
                                            </div>
                                            <div class="vx-col sm:w-1/3 w-full flex items-center">
                                                <div class="p-4 flex flex-col items-center w-full border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                                                    <div class="my-3">
                                                        <h5 class="font-bold line-through" v-if="item.original_price != item.price">{{ item.original_price }} руб.</h5>
                                                        <h5 class="font-bold text-center">{{ item.price }} руб.</h5>
                                                    </div>
                                                    <div v-if="isInOrder(item) != -1" 
                                                        class="flex flex-wrap">
                                                            <api-button 
                                                                :click="() => removeItemFromOrder(item)"
                                                                text-color="black" 
                                                                icon="close" 
                                                                type="flat">
                                                                    УДАЛИТЬ
                                                            </api-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </item-list-view>
                            </div>
                        </template>
                    </template>

                    <template v-if="catalogItems['timer-event']">
                        <div class="mb-2"><span class="font-bold">Билеты</span></div>
                        <template v-if="Object.keys(catalogTicketsGrouped).length > 1">
                            <vs-collapse type="margin">
                                <vs-collapse-item v-for="(group, index) in catalogTicketsGrouped" :key="index">
                                    <div class="font-semibold text-xl" slot="header">{{index}}</div>
                                    <div class="items-list-view" v-for="item in group" :key="item.objectID">
                                        <item-list-view :item="item">
                                            <template slot="body">
                                                <div class="vx-row item-details no-gutter">
                                                    <div class="absolute top-0 right-0 z-50 mt-3 mr-3"
                                                        v-if="item.trade_offers">
                                                            <vs-chip color="primary">Есть опции</vs-chip>
                                                    </div>
                                                    <div class="vx-col sm:w-3/4 cursor-pointer"
                                                        @click="cartButtonClicked(item)">
                                                        <div class="p-4 pt-6">
                                                            <h6 class="item-name text-xl font-semibold mb-2">{{ item.event_group_name }}</h6>
                                                            <h6 class="item-name text-xl text-primary font-semibold mb-2">{{ item.name }}</h6>
                                                            <p class="text-sm mb-1">Лимит посетителей: <span class="text-primary font-semibold">{{ item.visitors_limit }}</span></p>
                                                            <p class="text-sm mb-2">Осталось билетов: <span class="text-primary font-semibold">{{ item.stock_left }}</span></p>
                                                            <p class="text-sm mb-1">Тариф: <span class="text-primary font-semibold">{{ item.time_tariff }}</span></p>
                                                            <p class="item-description text-sm">Время проведения: с {{ $helpers.formatDateTime(item.event_start_time) }} по {{ $helpers.formatDateTime(item.event_end_time) }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                                                        <div class="p-4 flex flex-col w-full border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                                                            <div class="my-3">
                                                                <h5 class="font-bold line-through" v-if="item.original_price != item.price">{{ item.original_price }} руб.</h5>
                                                                <h5 class="font-bold text-center">{{ item.price }} руб.</h5>
                                                            </div>
                                                            <div v-if="isInOrder(item) != -1" 
                                                                class="flex flex-wrap">
                                                                    <api-button 
                                                                        :click="() => removeItemFromOrder(item)"
                                                                        text-color="black" 
                                                                        icon="close" 
                                                                        type="flat">
                                                                            УДАЛИТЬ
                                                                    </api-button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                        </item-list-view>
                                    </div>
                                </vs-collapse-item>
                            </vs-collapse>
                        </template>
                        <template v-else>
                            <div class="items-list-view" v-for="item in catalogItems['timer-event']" :key="item.objectID">
                                <item-list-view :item="item">
                                    <template slot="body">
                                        <div class="vx-row item-details no-gutter">
                                            <div class="absolute top-0 right-0 z-50 mt-3 mr-3"
                                                v-if="item.trade_offers">
                                                    <vs-chip color="primary">Есть опции</vs-chip>
                                            </div>
                                            <div class="vx-col sm:w-3/4 cursor-pointer"
                                                @click="cartButtonClicked(item)">
                                                <div class="p-4 pt-6">
                                                    <h6 class="item-name text-xl font-semibold mb-2">{{ item.event_group_name }}</h6>
                                                    <h6 class="item-name text-xl text-primary font-semibold mb-2">{{ item.name }}</h6>
                                                    <p class="text-sm mb-1">Лимит посетителей: <span class="text-primary font-semibold">{{ item.visitors_limit }}</span></p>
                                                    <p class="text-sm mb-2">Осталось билетов: <span class="text-primary font-semibold">{{ item.stock_left }}</span></p>
                                                    <p class="text-sm mb-1">Тариф: <span class="text-primary font-semibold">{{ item.time_tariff }}</span></p>
                                                    <p class="item-description text-sm">Время проведения: с {{ $helpers.formatDateTime(item.event_start_time) }} по {{ $helpers.formatDateTime(item.event_end_time) }}</p>
                                                </div>
                                            </div>
                                            <div class="vx-col sm:w-1/4 w-full flex items-center">
                                                <div class="p-4 flex flex-col w-full border border-solid d-theme-border-grey-light border-r-0 border-t-0 border-b-0">
                                                    <div class="my-3">
                                                        <h5 class="font-bold line-through" v-if="item.original_price != item.price">{{ item.original_price }} руб.</h5>
                                                        <h5 class="font-bold text-center">{{ item.price }} руб.</h5>
                                                    </div>
                                                    <div v-if="isInOrder(item) != -1" 
                                                        class="flex flex-wrap">
                                                            <api-button 
                                                                :click="() => removeItemFromOrder(item)"
                                                                text-color="black" 
                                                                icon="close" 
                                                                type="flat">
                                                                    УДАЛИТЬ
                                                            </api-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </item-list-view>
                            </div>
                        </template>
                    </template>
                </template>

                <vx-card class="text-center"
                    v-if="!catalogItems && filterForm.search">
                    <p class="text-lg">"Ничего не нашлось ☹</p>
                </vx-card>

                <vs-popup title="Введите e-mail посетителя" class="button-close-hidden" :active.sync="customerEmailPrompt">
                    <div v-if="$store.state.activeClient">
                        <vs-input class="w-full" icon-pack="feather" icon="icon-mail" icon-no-border 
                            v-model="customerEmail" name="email" v-validate="'required|email'" />
                        <span class="text-danger">{{ errors.first('email') }}</span>
                    </div>
                    <div v-else>
                        Для регистрации на мероприятие требуется указать e-mail. 
                    </div>
                    <vs-divider />
                    <div class="flex flex-wrap">
                        <vs-button class="mb-2 mx-2 flex-auto" 
                            @click="updateActiveCustomerEmail">
                            СОХРАНИТЬ
                        </vs-button>
                        <vs-button class="mb-2 mx-2 flex-auto" 
                            @click="$store.commit('SET_CUSTOMER_EMAIL', null); customerEmailPrompt = false;">
                            ОТМЕНА
                        </vs-button>
                    </div>
                </vs-popup>
            </div>

        </div>
    </div>
</template>

<script>
// TODO пересмотреть все случаи использования и отрефакторить данный компонент - чтоб было более очевидно что происходит
// 1) сделать отдельные подкомпоненты для товаров в каталоге
// 2) запутанно реализован выбор сценария использования - товары или билеты - и пропы, и гуиды итд
// 3) для билетов еще есть группировка по мероприятиям и поиск по дате
// 4) может сделать для билетов отдельный компонент?
// 5) вид отображения каталога (списком или плиткой) - "оптимизировать"? - слишком много if-ов и копипасты в шаблоне

var qs = require('qs');
var _debounce = require('lodash/debounce');
var _groupby = require('lodash/groupBy');

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Russian as RussianLocale} from 'flatpickr/dist/l10n/ru.js';

export default {
    components: {
        ItemGridView: () => import("./eCommerce/components/ItemGridView.vue"),
        ItemListView: () => import("./eCommerce/components/ItemListView.vue"),
        flatPickr
    },
    props: ['order', 'root_category_id', 'skip_update', 'single_item_order'],
    data() {
        return {
            customerEmailPrompt: false,
            itemClicked: null,

            category_id: this.root_category_id,

            currentItemView: 'item-list-view',

            guids_list: [
                {label: 'Все', value: 'catalog-object'},
                {label: 'Товары', value: 'timer-bar-item'},
                {label: 'Билеты', value: 'timer-event'}
            ],

            filterForm: {
                active_tariff_object_id: this.order.tariff_object_id, 
                active_client_type_id: this.order.client_type_id,
                search_name: '',
                date_from: null,
                date_to: null,
                guid: ''
            },

            catalogSubcategories: {},
            catalogItems: {},

            itemsBlockTitle: 'Товары',

            configdateTimePicker: {
                dateFormat: 'd/m/Y',
                locale: RussianLocale,
                allowInput: true,
                disableMobile: true
            }
        }
    },
    watch: {
        'filterForm.guid': function() {
            if (this.filterForm.guid == this.guids_list[1]) {
                this.filterForm.date_from = null;
                this.filterForm.date_to = null;
            }
            this.debouncedGetCatalogItems(this.category_id);
        },
        'filterForm.date_from': function() {
            if (this.filterForm.guid != this.guids_list[2] && this.filterForm.date_to) {
                this.filterForm.guid = this.guids_list[2];
            }
            if (!this.filterForm.date_to) {
                this.filterForm.date_to = this.filterForm.date_from;
            }
        },
        'filterForm.date_to': function() {
            if (this.filterForm.guid != this.guids_list[2] && this.filterForm.date_to) {
                this.filterForm.guid = this.guids_list[2];
            }
        }
    },
    computed: {
        isInOrder() {
            return (item) => this.$store.getters['eCommerce/isInOrder'](this.order, item)
        },
        catalogTicketsGrouped: function() {
            if (!this.catalogItems['timer-event']) return null;

            var grouped = _groupby(this.catalogItems['timer-event'], function(event) {
                return event.event_group_name;
            });
            return grouped;
        },
        customerEmail: {
            get() { return this.$store.state.activeClient.info['e-mail'] },
            set(value) {  
                this.$store.commit('SET_CUSTOMER_EMAIL', value);
            }
        }
    },
    methods: {
        setCurrentItemView(itemView) {
            this.currentItemView = itemView;
            localStorage.setItem('catalogItemView', itemView);
        },

        updateActiveCustomerEmail() {
            this.$validator.validateAll()
            .then(result => {
                if (result) { 
                    let callback = () => { 
                        this.cartButtonClicked(this.itemClicked);
                    };
                    this.$store.dispatch('updateActiveCustomer', callback);
                    this.customerEmailPrompt = false;
                }
            })
        },

        getCatalogItems($category_id = this.category_id) {
            let payload = Object.assign({}, this.filterForm);
            if (payload.date_from) {
                let date_from = payload.date_from.split('/');
                date_from = new Date(date_from[2], date_from[1] - 1, date_from[0], 0, 0, 0);
                payload.date_from = Math.round(date_from.valueOf() / 1000);
            }
            if (payload.date_to) {
                let date_to = payload.date_to.split('/');
                date_to = new Date(date_to[2], date_to[1] - 1, date_to[0], 23, 59, 59);
                payload.date_to = Math.round(date_to.valueOf() / 1000);
            }
            if (this.root_category_id === this.$globals.EVENTS_ROOT_CATEGORY_ID) {
                let date_to = new Date();
                date_to.setDate(date_to.getDate() + 1);
                date_to.setHours(11, 0, 0);
                payload.date_to = Math.round(date_to.valueOf() / 1000);
            }
            payload.category_id =  $category_id;
            payload.root_category_id = this.root_category_id;

            this.$http({
                method: 'POST',
                url: '/udata/catalog/get_catalog_items/.json',
                data: qs.stringify(payload),
                blocking: false
            }).then((response) => {
                if (!response.data.error) {
                    this.catalogSubcategories = response.data.subcategories;
                    this.catalogItems = response.data.items;
                    this.itemsBlockTitle = 'Товары';
                } else {
                    this.$vs.notify({
                        title:'Ошибка getCatalogItems',
                        text: response.data.error,
                        color:'warning',
                        iconPack: 'feather',
                        icon:'icon-alert-circle'
                    })          
                }
            })
            .catch((error) => {
                this.$vs.notify({
                    title:'Ошибка getCatalogItems',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                })
            });
        },

        // ACTIONS
        categoryButtonClicked(item) {
            this.category_id = item.objectID;
            this.getCatalogItems();
        },
        async cartButtonClicked(item) {
            if (item.visitors_limit === 0) {
                this.$vs.notify({
                    title:'Невозможно добавить мероприятие',
                    text:'На мероприятие уже записано максимальное количество посетителей',
                    color:'warning',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'
                });
                return;
            }

            if (item.trade_offers) {
                this.catalogSubcategories[item.object_guid] = [{
                    image:"",
                    name:"Назад",
                    objectID: this.category_id
                }];
        
                this.catalogItems[item.object_guid] = item.trade_offers;
                this.itemsBlockTitle = 'Опции для "' + item.name +'"';
                return;
            }

            if (this.single_item_order && this.order.items.length > 0) return;
            let index = this.isInOrder(item);
            let callback = () => { 
              this.getCatalogItems();
            };
            if (index != -1) {
                this.$store.dispatch("eCommerce/updateItemQuantity", 
                                    {item_amount: this.order.items[index].item_amount + 1, index: index, order: this.order, callback: callback, skip_update: this.skip_update});
            } else {
                this.$store.dispatch('eCommerce/addItemToOrder', {item: item, order: this.order, callback: callback, skip_update: this.skip_update})
            }
        },
        async removeItemFromOrder(item) {
            await this.$store.dispatch('eCommerce/removeItemFromOrder', {item: item, order: this.order, skip_update: this.skip_update});
            this.debouncedGetCatalogItems();
        }
    },
    created() {
        this.currentItemView = localStorage.getItem('catalogItemView') ?? 'item-list-view';

        this.filterForm.guid = this.guids_list[0];
        this.getCatalogItems();
        this.debouncedGetCatalogItems = _debounce(this.getCatalogItems, 500);
    },
}
</script>

<style lang="scss">
@import "src/assets/scss/vuesax/_variables.scss";

#catalog-container {
    .item-view-primary-action-btn {
        color: #2c2c2c !important;
        background-color: #f6f6f6;
        min-width: 50%;
    }
    .back-category-btn {
        border: 3px solid $danger;
    }
}
.button-close-hidden .vs-popup--close {
    display: none;
}
</style>
